import axios from "axios";


export const header = {
  return: {
    "Content-Type": "multipart/form-data",
  }
}

export const downloadZip = (
  file: any, 
  sucursal: string,
  ubicacion: string,
  onUploadProgress: any
) => {
    
  let formData = new FormData();
  formData.append("file", file);
  formData.append("sucursal", sucursal);
  formData.append("ubicacion", ubicacion);

  return axios.post(
    process.env.REACT_APP_BASE_URL+"/v1/converter", 
    formData, {
      headers: header,
      onUploadProgress,
      responseType: "arraybuffer"
    }
  );
}

export const getCatalogue = () => {
  return axios.get(
    process.env.REACT_APP_BASE_URL+"/v1/branch-offices", 
  );
}
