import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { downloadZip } from "../services/microtec.service";

export const emptyDragDrop: DragDropProps = {
    selectedFile: undefined,
    currentFile: undefined,
    progress: 0,
    message: "",
    success: "",
}

type DragDropProps = {
  selectedFile: File | undefined;
  currentFile: File | undefined;
  progress: number;
  message: string;
  success: string;
};

type UploadFilesProps = {
  branchOfficeSelect: string;
  locationSelect: string;
}

const UploadFiles = ({
  branchOfficeSelect,
  locationSelect
}: UploadFilesProps) => {

  const [{
    selectedFile, 
    currentFile, 
    progress, 
    message,
    success
  }, setProps] = useState(emptyDragDrop);

  const upload = () => {

    setProps({
      selectedFile: undefined,
      currentFile: selectedFile,
      progress: 0,
      message: "",
      success: ""
    })

    downloadZip(selectedFile, branchOfficeSelect, locationSelect, (event: any) => {
      setProps(prevState => ({
        ...prevState,
        progress: Math.round((100 * event.loaded) / event.total),
      }))
    })
    .then((response) => {

      setProps(prevState => ({
        ...prevState,
        success: "¡El proceso termino correctamente!",
      }));

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "resultados.zip");
      document.body.appendChild(link);
      link.click();

    })
    .catch((error) =>
      setProps(prevState => ({
        ...prevState,
        progress: 0,
        message: error.response.status === 500 
          ? "Ocurrio un error en el servidor al procesar el archivo"
          : "¡No pudimos enviar tu archivo!", // FIXME:
        currentFile: undefined,
      }))
    );
  }

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {

    const acceptedFile = acceptedFiles[0];
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {

      setProps({
        currentFile: undefined,
        selectedFile: acceptedFile,
        message: "",
        progress: 0,
        success: ""
      });
    }

    reader.readAsArrayBuffer(acceptedFile)

  }, [setProps])

  const {getRootProps, getInputProps} = useDropzone({
    accept: ["application/x-zip-compressed", "application/zip"],
    maxFiles: 1,
    onDropAccepted
  })
  
  return (
    <div>
      { message !== "" &&
        <div className="alert alert-danger" role="alert">
          {message}
        </div>
      }
      
      {currentFile && (
        <div className="progress mb-3">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}

      <div {...getRootProps({ className: "dropzone" })} >
        <input 
          {...getInputProps()} 
          accept="application/zip, application/x-zip-compressed"
        />
        {selectedFile && selectedFile.name ? (
          <div className="selected-file">
            {selectedFile && selectedFile.name}
          </div>
        ) : (
          "Arrastra tu archivo aquí, o da click para seleccionarlo"
        )}
      </div>

      { success !== "" &&
        <div className="alert alert-light" role="alert" >
          <h5>{success}</h5>
        </div>
      }

      <aside className="selected-file-wrapper">
        <button
          className="btn btn-success"
          disabled={!selectedFile}
          onClick={upload}
        >
          Generar XML
        </button>
      </aside>
    </div>
  )
}

export default UploadFiles;
