import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getCatalogue } from "./services/microtec.service";
import BranchOffices from "./components/autocomplete-component";

export const emptyCatalogue = {
  key: "",
  description: ""
}

function App() {

  const [branchOffices, setBranchOffices] = useState([emptyCatalogue]);
  const [message, setError] = useState("");

  useEffect(() => {
    getCatalogue()
    .then((response) => 
      setBranchOffices(response.data)
    )
    .catch((error) =>
      setError("No se pudo obtener la información de las sucursales")
    );
  }, []);

  return (

    <div className="container" style={{ width: "600px" }}>

      <div style={{ margin: "20px 0" }}>
        <h2>Convertir csv a xml</h2>
        <h4>Sube tus archivos .zip</h4>
      </div>

      { message !== "" ? (
        <div className="alert alert-danger" role="alert">
        {message}
        </div>
      ) : (
        <>
          <BranchOffices
            data={branchOffices} 
          />
        </>
      )}
    </div>
  );
}

export default App;
