import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import UploadFiles from "./upload-files.component";

type BranchOfficesProps = {
  data: any[];
};

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18
    }
  }
});

const autocompleteStyle = {
  marginTop: "35px"
};

const BranchOffices = ({
  data
}: BranchOfficesProps) => {

  const classes = useStyles();
  const [branchOfficeSelect, setBranchOffice] = useState("");
  const [locationSelect, setLocation] = useState("");

  useEffect(() => {}, [branchOfficeSelect]);

  data = data.sort((a, b) => a.group.localeCompare(b.group));
  
  return (
    <div style={autocompleteStyle}>
      <Autocomplete
        id="branch-offices"
        style={{ width: 570, marginBottom: "30px" }}
        options={data}
        groupBy={(option) => option.group}
        classes={{
          option: classes.option
        }}
        autoHighlight
        getOptionLabel={option => option.description}
        renderOption={option => (
          <React.Fragment>
            {option.description}
          </React.Fragment>
        )}
        onChange={(event, newValue) => {
          if (newValue !== null) { 
            setBranchOffice(newValue.key); 
            setLocation(newValue.group); 
          } else {
            setBranchOffice("");
            setLocation("");
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Selecciona una sucursal"
            variant="outlined"
            fullWidth
            inputProps={{
              ...params.inputProps,
              autoComplete: "disabled" // disable autocomplete and autofill
            }}
          />
        )}
      />

      { branchOfficeSelect !== "" &&
        <>
          <UploadFiles 
            branchOfficeSelect={branchOfficeSelect}
            locationSelect={locationSelect}
          />
        </>
      }

    </div>  
  );
}

export default BranchOffices;
